const Reservations = require("../../assets/Reservations-Manager.png");

const data = [
    {
        id: 1,
        name: "Reservations Manager",
        description:
            "The Restaurant Reservations Application is a comprehensive management system that helps to handle and streamline reservations and seating at a restaurant.",
        github: "https://github.com/stable-release/reservations-capstone",
        website: "https://reservations-frontend.onrender.com",
        img: Reservations,
    },
];

module.exports = data;
