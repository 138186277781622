import { ConfigProvider, Layout, theme } from "antd";
import { useState } from "react";
import Navbar from "./components/Navbar";
import Cover from "./components/Cover";
import "./index.css";
import { Content, Header } from "antd/es/layout/layout";
import LightsContext from "./contexts/LightsContext";

const { defaultAlgorithm, darkAlgorithm } = theme;

function Frontend() {
    const [isDark, setIsDark] = useState(false);
    const darkmode = () => {
        setIsDark((prev) => !prev);
    };

    return (
        <ConfigProvider
            theme={{
                algorithm: isDark ? defaultAlgorithm : darkAlgorithm,
                token: { colorPrimary: isDark ? "rgba(80, 0, 0, 0.8)" : "#d3f261" },
            }}
        >
            <Layout
                style={{
                    background: !isDark ? "#161616" : "#FFF",
                    minHeight: "100vh",
                    width: "100vw",
                }}
            >
                <Header
                    style={{
                        background: !isDark ? "#161616" : "#FFF",
                    }}
                >
                    <Navbar darkmode={darkmode} isDark={isDark} />
                </Header>

                <Content
                    style={{
                        marginLeft: "10vw",
                        marginRight: "10vw",
                    }}
                >
                    <LightsContext.Provider value={{ isDark }}>
                        <Cover />
                    </LightsContext.Provider>
                </Content>

                {/* <Footer></Footer> */}
            </Layout>
        </ConfigProvider>
    );
}

export default Frontend;
