import { Col, Row, Space } from "antd";
import Introduction from "./Introduction";
import ProjectCards from "./ProjectCards";

export default function Cover() {
    return (
        <Space direction="vertical">
            <Row>
                <Col>
                    <Introduction />
                </Col>
            </Row>
            <Row>
                <ProjectCards />
            </Row>
        </Space>
    );
}
