import { Space, Typography } from "antd";
import { useContext } from "react";
import LightsContext from "../../contexts/LightsContext";

const { Title } = Typography;

export default function Introduction() {
    const { isDark } = useContext(LightsContext);
    const styling = isDark
        ? { color: "rgba(0, 0, 0, 0.88)" }
        : {
              background: "linear-gradient(in hsl longer hue 45deg, red 0 0)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
          };

    return (
        <Space direction="vertical">
            <Title level={1} style={{}}>
                Hi, I'm{" "}
                <span
                    style={{ color: isDark ? "rgba(80, 0, 0, 1)" : "#FFFFFF" }}
                >
                    Kevin Lin
                </span>
                , <br /> <span style={styling}>Fullstack</span> Developer
                skilled in <br />
                <span
                    style={
                        isDark
                            ? { color: "rgba(0, 0, 0, 0.88)" }
                            : { color: "#61DBFB" }
                    }
                >
                    React
                </span>
                ,{" "}
                <span
                    style={
                        isDark
                            ? { color: "rgba(0, 0, 0, 0.88)" }
                            : { color: "#62F40C" }
                    }
                >
                    Express
                </span>
                , and{" "}
                <span
                    style={
                        isDark
                            ? { color: "rgba(0, 0, 0, 0.88)" }
                            : { color: "#FFF40A" }
                    }
                >
                    Web Apps
                </span>
                <br />
                Check out my projects:
            </Title>
        </Space>
    );
}

/* <Text
                style={{
                    display: "flex",
                    alignItems: "end",
                    flexDirection: "row",
                }}
            >
                <h1>&gt; Hi, I'm&nbsp;</h1>
                <h1
                    style={{
                        background:
                            "linear-gradient(in hsl longer hue 45deg, red 0 0)",
                        webkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",

                    }}
                >
                    Kevin Lin
                </h1>
            </Text> */
