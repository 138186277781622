import { Switch, Row, Col, Space } from "antd";
import { ReactComponent as On } from "../assets/lightsOn.svg";
import { ReactComponent as Off} from "../assets/lightsOff.svg";

const lightOn = (
    <div
        style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
        }}
    ><On /></div>
);

const lightOff = (
    <div
        style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
        }}
    ><Off /></div>
);

export default function Navbar({ darkmode, isDark }) {
    return (
        <Row>
            <Col flex={6}>
                <Space align="left">
                    Hosted on <a href="https://azure.microsoft.com/en-us">Azure</a>
                </Space>
            </Col>
            <Col flex={1}>
                <Space align="center">
                    <Switch defaultChecked={false} onChange={() => darkmode()} />
                    {isDark ? lightOn : lightOff}
                </Space>
            </Col>
        </Row>
    );
}
