import { Card, Col, Row } from "antd";
import { GithubOutlined } from "@ant-design/icons";
import data from "./data";
const { Meta } = Card;

function ProjectCards() {
    const handleLink = (website) => {
        window.open(website);
    };

    const projects = data.map((project, index) => {
        return (
            <Col
                key={index}
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                style={{}}
            >
                <Card
                    hoverable
                    cover={
                        <img
                            alt={project.name}
                            src={project.img}
                            onClick={() => handleLink(project.website)}
                        />
                    }
                    style={{ maxHeight: "100%", maxWidth: "95%" }}
                    actions={[
                        <a href={project.github}>
                            <GithubOutlined
                                key="github"
                                onClick={() => handleLink(project.github)}
                            />
                        </a>,
                    ]}
                >
                    <Meta
                        title={project.name}
                        description={project.description}
                    />
                </Card>
            </Col>
        );
    });

    return (
        <Row xs={{ gutter: 0 }} sm={{ gutter: [16, 16] }}>
            {projects}
        </Row>
    );
}

export default ProjectCards;
